import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login.js";
import NoPage from "./pages/NoPage.js";
import Landing from "./pages/Landing.js"
import ReDesignPage from "./pages/ReDesignPage.js";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<Landing />}/>
        <Route path="*" element={<NoPage />}/>

        <Route path="Login" element={<Login />}/>
        <Route path="Home" element={<ReDesignPage/>}/>

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);