// Default imports
import logo from './logo.png';
import './Home.css';

// Auth related imports
import { Amplify } from 'aws-amplify'
import { Auth } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify/utils';
import { Alert, withAuthenticator } from "@aws-amplify/ui-react";

// Config amplify
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '58tbkit3gvfmf1ge7arobcluv5',
      userPoolId: 'us-east-1_PK5vU8eMG',
      loginWith: { // Optional
        oauth: {
          domain: 'abcdefghij1234567890-29051e27.auth.us-east-1.amazoncognito.com',
          scopes: ['profile aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/','https://example.com/'],
          redirectSignOut: ['http://localhost:3000/','https://example.com/'],
          responseType: 'code',
        },
        email:'true',
        preferred_username: 'true',
        birthdate: 'true',
      },
    }
  }
});

// Login function
function Login({ signOut, user }) {

    // HTML JSX thingy
    return (
    <div className="App">
      <div id ="Background">
          <div id="division">
              <h1 id="title">af-comms</h1>
              <h2 id="sub-title">Logged in</h2>
          </div>

          <div id="division">
              <h3>You have logged in into your af-comms account</h3>
          </div>

          <button onClick={signOut}>Sign out</button>
      </div>
    </div>
    );
}



export default withAuthenticator(Login);
  
