
function NoPage() {
  return (
  <div className="App">
    <div id ="Background">
        <div id="division">
            <h1 id="title">af-comms</h1>
            <h2 id="sub-title">404 PAGE NOT FOUND</h2>
        </div>
    </div>
  </div>
  );
}

export default NoPage;
