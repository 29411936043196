
function ReDesignPage() {
    return (
    <div className="App">
      <div id ="Background">
          <div id="division">
              <h1 id="title">af-comms</h1>
              <h2 id="sub-title">Major update undergoing!</h2>
          </div>

          <div id="division">
            <p>Hello , this website is undergoing a major user interface update.</p>
            <p>Because of many requests and criticisms , i am creating a brand new look</p>
            <p>There may be also new stuff comming soon! More tan just a static website.</p>
            <p>If you wish to contact me , then join my discord server and ask me there.</p>

            <a href="https://discord.gg/85MY3KYpmS">My discord server</a>
            
          </div>
      </div>
    </div>
    );
  }
  
  export default ReDesignPage;
  